import React from 'react';
import { R } from '@dev2t/react-ui-kit/src/components';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { env } from '@config';

const useStyles = makeStyles(theme => ({
    link: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
	},
}));

export default function Disclosures() {
	const classes = useStyles();
	return (
		<Box>
			<Typography variant='h5'>
				<R id='common_menu_disclosures' defaultValue='Disclosures'></R>
			</Typography>
			<a className={classes.link} 
				href={`${env.backendUrl}files/j2tx/Brokerage%20Regulations.pdf`} 
				target="_blank">
				<R id="shared_brokerage_regulations_doc_title" defaultValue="Brokerage Regulations"/>
			</a>
		</Box>
	);
}
