import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ProgressContainer, FormDatePicker } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage, FormInput } from '@dev2t/react-ui-kit/src';
import { translate } from '@global';
import ResetByEmailForm from './components/ResetByEmailForm';
import ResetByOperatorForm from './components/ResetByOperatorForm';
import { Field } from 'redux-form';

export const ResetViewLme = () => {
	const [step, setStep] = useState('resetByEmail');
	const [error, setError] = useState('');
    const [title, setTitle] = useState(translate('reset_password_form_title', 'Password recovery'));
	const dispatch = useDispatch();
	const submitting = false;
	const goToLogin = () => dispatch(push('/login'));
	const resetForm = () => setStep('resetByEmail');

    useEffect(() => {
		const title = ['resetByOperator', 'succeededOperator', 'failedOperator'].includes(step) 
		? translate('reset_password_form_title_step_2', 'Password recovery (2)') 
		: translate('reset_password_form_title', 'Password recovery');

		setTitle(title);
    }, [step]);


	return (
		<Modal
			title={title}
			onClose={goToLogin}
		>
			<ProgressContainer submitting={submitting}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={goToLogin}
							text={
								<p>
									<R id="reset_password_success_message_unauthorized" 
										defaultValue="Please check your email for a change password link. In case you have not received it, please contact support." />
								</p>
							}
						/>
					}
					{(step === 'failed' || step === 'failedOperator') &&
						<ErrorMessage onClick={resetForm} text={error || translate('reset_view_error_password_recovery', 'Could not password recovery')} />
					}
					{step === 'resetByEmail' &&
						<ResetByEmailForm
							enableReinitialize
							forceUnregisterOnUnmount
							lockEmailFieldAfterSubmit
							onSuccess={() => setStep('succeeded')}
                            setTitle={setTitle}
							onSubmitFail={(errorInfo) => { setError(errorInfo._error); setStep('failed'); }}
							onOperatorSended={() => setStep('resetByOperator')}
							additionalFieldsForEmailSubmit={<Field component={FormDatePicker} name="DateOfBirth" label={translate('reset_form_date_of_birth', 'Date of birth')}/>}
						/>
					}
					{step === 'succeededOperator' &&
						<SuccessMessage
							onClick={goToLogin}
							text={
								<React.Fragment>
									<p>
										<R id="reset_view_success_operator_first" defaultValue="Your form has been successfully sent." />

									</p>
									<p>
										<R id="reset_view_success_operator_second" defaultValue="Operator will contact you soon to help to recover password from your account." />
									</p>
								</React.Fragment>
							}
						/>
					}
					{step === 'resetByOperator' &&
						<ResetByOperatorForm
							enableReinitialize
							forceUnregisterOnUnmount
							onSuccess={() => setStep('succeededOperator')}
							onSubmitFail={(errorInfo) => { setError(errorInfo._error); setStep('failedOperator'); }}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};
