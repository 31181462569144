import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { userAccounts } from '@redux';
import compose from 'recompose/compose';
import { A } from '@components';
import { clearInterval, setInterval } from '@dev2t/react-ui-kit/src';

// Минимальное время между запросами на смену пароля транзака
const TransaqChangePasswordIntervalSeconds = 60;

const mapStateToProps = state => ({
	currentTradeAccount: userAccounts.selectors.currentTradeAccount(state),
	tradeSystemCredentialsRequest: userAccounts.selectors.getTradeSystemCredentialsRequest(state),
	accountInfo: userAccounts.selectors.accountInfo(state),
});


const mapDispatchToProps = dispatch => ({
	actions: {
		getTradeSystemCredentials: (tradeAccount) => {
			dispatch(userAccounts.actions.getTradeSystemCredentials.request(tradeAccount));
		},
	},
});

class TransaqChangePasswordButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			secondRemains: this.getSecondRemains(),
		};
	}
	componentDidMount() {
		const secondRemains = this.getSecondRemains();
		if (secondRemains > 0) {
			this.timer = setInterval(this.onTimer, 1000);
		}
	}
	componentDidUpdate() {
		const secondRemains = this.getSecondRemains();
		if (secondRemains > 0 && !this.timer) {
			this.timer = setInterval(this.onTimer, 1000);
		}
	}
	componentWillUnmount() {
		if (this.timer) {
			clearInterval(this.timer);
			this.timer = 0;
		}
	}
	onTimer = () => {
		const secondRemains = this.getSecondRemains();
		if (secondRemains <= 0) {
			clearInterval(this.timer);
			this.timer = 0;
		}
		this.setState({
			...this.state,
			secondRemains,
		});
	}
	getSecondRemains = () => {
		const { tradeSystemCredentialsRequest } = this.props;
		const remains = tradeSystemCredentialsRequest ?
			TransaqChangePasswordIntervalSeconds - Math.floor((new Date() - tradeSystemCredentialsRequest.RequestDate) / 1000) : 0;
		return remains > 0 ? remains : 0;
	}
	getTradeSystemCredentials = () => {
		const {
			actions,
			currentTradeAccount: {
				tradeCode,
				clearerFirm,
			},
		} = this.props;
		actions.getTradeSystemCredentials({ tradeCode, clearerFirm });
	}


	render() {
		const {
			classes,
			accountInfo,
			tradeSystemCredentialsRequest,
		} = this.props;
		const { secondRemains } = this.state;
		const submitting = tradeSystemCredentialsRequest && tradeSystemCredentialsRequest.submitting;
		const inProgress = submitting || (secondRemains > 0);
		const btProps = {};
		if (inProgress) {
			btProps.color = 'disable';
		} else {
			btProps.onClick = this.getTradeSystemCredentials;
		}
		const minutes = Math.ceil(TransaqChangePasswordIntervalSeconds / 60);
		return (
			<React.Fragment>
				<div className={classes.editable}>
					<div className={classes.staticValue}>
						{submitting &&
							<div>
								You can try again request credentials in {minutes} minute{ minutes > 0 ? 's' : ''}.
							</div>
						}
						{(secondRemains > 0) &&
							<div>
								You can try again request credentials in {secondRemains} seconds.
							</div>
						}
					</div>
					<div className={classes.editItem}>
						{accountInfo && accountInfo.Platforms && accountInfo.Platforms.ActiveTradePlatform === 'Transaq' &&
							<div>
								<A {...btProps} upper>Request Credentials</A>
							</div>
						}
					</div>
				</div>
			</React.Fragment>
		);
	}
}
TransaqChangePasswordButton.defaultProps = {
	tradeSystemCredentialsRequest: undefined,
};
TransaqChangePasswordButton.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	accountInfo: PropTypes.object.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	tradeSystemCredentialsRequest: PropTypes.object,
};


export default compose(
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(TransaqChangePasswordButton);
