import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { R } from '@dev2t/react-ui-kit/src/components';
import Box from '@material-ui/core/Box';
import mc from './img/mc.svg';
import vs from './img/vs.jpg';
import up from './img/up.png';


const styles = theme => ({
	root: {
		color: '#818181',
		fontSize: '.65rem',
		lineHeight: 2,
		textRendering: 'optimizeLegibility',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'top',
		flexWrap: 'wrap',
		'& a': {
			color: theme.palette.primary.main,
			textDecoration: 'none',
		},
		'& img': {
			maxHeight: '25px',
		},
		'& span': {
			fontSize: '1.7em',
		},
		'& p': {
			margin: '0 0 5px 0',
		},
	},
	container: {
		flex: '1 3 420px',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
});

const MainSection = withStyles({
	root: {
		padding: '8px',
		flex: '2 1 300px',
		textAlign: 'justify',
		paddingRight: '20px',
	},
})(Box);

const WarningSection = withStyles({
	root: {
		flex: '1 1 250px',
		padding: '8px',
		paddingRight: '20px',
	},
})(Box);

const ContactsSection = withStyles({
	root: {
		flex: '2 0 170px',
		padding: '8px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		textAlign: 'right',
		lineHeight: '1.5rem',
	},
})(Box);


function MainFooterLME(props) {
	const {
		classes,
	} = props;

	return (
		<Paper>
			<Box className={classes.root} px={5} py={3}>
				<MainSection>
					<span><b>© {new Date().getFullYear()} Lime LTD</b></span>
					<p>
					Lime LTD <R id="footer_authorised_with_license" />
					</p>
					<p>
						<b><R id="registration_number" /></b>: 26796 BC 2022 dated 8th July, 2022
					</p>
					<p>
						<b><R id="shared_address" /></b>: Euro House
					Richmond Hill Road P.O. Box 2897, Kingstown
					Saint Vincent and the Grenadines
					</p>
					<p>
						<b><R id="footer_disclaimer_title" />: </b>
						<R id="footer_disclaimer_content" />
					</p>
				</MainSection>
				<Box className={classes.container}>
					<WarningSection>
						<b><R id="footer_risk_warning_header" /></b>
						<br />
						<R id="footer_risk_warning" /> { }
						<a href="https://j2t.global/files/limeltd/regulatorydocs/Brokerage%20Regulations.pdf" target="_blank">
							<R id="shared_brokerage_regulations_doc_title" defaultValue="Brokerage Regulations"/>
						</a>
					</WarningSection>
					<ContactsSection >
						<Box>
						E-mail: <a href="mailto:24_support@j2t.global">24_support@j2t.global</a>
							<br />
							<a href="https://j2t.global/files/limeltd/regulatorydocs/Privacy%20Policy.pdf" target="_blank">
								<R id="about_docs_privacy_policy_title" />
							</a>
							<br />
							<a href="https://j2t.global/files/limeltd/regulatorydocs/Terms%20and%20Conditions%20Lime%20Ltd.pdf" target="_blank">
								<R id="about_docs_terms_and_conditions_title" />
							</a>
							<br />
							<a href="https://j2t.global/files/limeltd/regulatorydocs/Restrictions%20Lime%20Ltd.pdf" target="_blank">
								<R id="about_docs_restrictions_title" />
							</a>
							<br />
							<a href="https://j2t.global/files/limeltd/regulatorydocs/AML%20CTF%20Statement%20Lime%20Ltd.pdf" target="_blank">
								<R id="about_docs_aml_risk_management_procedures_title" />
							</a>
							<br />
							<a href="https://j2t.global/about/" target="_blank">
								<R id="about_company_title" />
							</a>
							<br />
						</Box>
						<Box paddingTop={1} display="flex" flexWrap="wrap" justifyContent="flex-end">
							<Box marginRight={3}><img src={vs} alt="visa secure" /></Box>
							<img src={mc} alt="mastercard" />
							<Box marginLeft={3}><img src={up} alt="union pay" /></Box>
						</Box>
					</ContactsSection>
				</Box>
			</Box>
		</Paper>
	);
}

export default compose(
	withTheme,
	withStyles(styles),
)(MainFooterLME);
