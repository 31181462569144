import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ProgressContainer } from '@components';
import { reset } from '@redux';
import { CYChangePasswordForm } from '@shared';
import { translate } from '@global';
import { Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import { env, constants } from '@config';


export const ResetPasswordView = ({ match }) => {
	const [step, setStep] = useState('resetByEmail');
	const dispatch = useDispatch();

	const submitting = false;

	const goToLogin = () => dispatch(push('/login'));
	const resetForm = () => dispatch(push('/reset'));

	return (
		<Modal
			title={env.firm === constants.LIME_LME
				? translate('reset_password_form_title', 'Password recovery') 
				: `${translate('reset_form_title', 'Password recovery, step')} #2`}
			onClose={goToLogin}
		>
			<ProgressContainer submitting={submitting}>
				<div>
					{step === 'failed' &&
						<ErrorMessage onClick={resetForm} text={translate('reset_form_error_message', 'Password recovery link is incorrect. Please, try again.')} />
					}
					{step === 'resetByEmail' &&
						<CYChangePasswordForm
							enableReinitialize
							forceUnregisterOnUnmount
							resetKey={match.params.emailAuthKey}
							onSubmit={reset.actions.resetPassword}
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={goToLogin}
							text={
								<React.Fragment>
									<p>
										{translate('reset_form_success_message_first', 'Change password completed.')}
									</p>
									<p>
										{translate('reset_form_success_message_second', 'Go to login page.')}
									</p>
								</React.Fragment>
							}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};
