import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { reduxForm, formValueSelector } from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { env } from '@config';
import { translate } from '@global';
import { userAccounts, app } from '@redux';
import { Mt4Img, Mt5Img } from '@content/mtLogos';
import { R } from '@dev2t/react-ui-kit/src/components';
import { getOpenAccountOutConfiguration } from '../openAccountOutConfiguration';
import { Platform } from '../../../../../../../../../utils/platforms';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	platformCard: {
		height: '100%',
		textAlign: 'center',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
	},
	platformCardSelected: {
		borderColor: theme.palette.text.secondary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
	methodImgBW: {
		filter: 'grayscale(100%)',
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const language = app.selectors.language(state);

	const initialValues = {
		ClientData: {
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
	};
	const loading = userAccounts.selectors.loading(state);
	const userInfo = userAccounts.selectors.userInfo(state);
	const userGuid = loading ? null : (userInfo.Guid && userInfo.Guid.toLowerCase());
	const OpenAccountOut = formValueSelector(ownProps.form)(state, 'OpenAccountOut');
	return {
		initialValues,
		OpenAccountOut,
		currentTradeAccount,
		language,
		userGuid,
	};
};

const getPlatformsViews = (userGuid) => [
	{
		SelectedPlatform: Platform.MT4,
		Title: translate('common_open_account_mt4_title', 'MetaTrader 4'),
		Img: Mt4Img,
		Enable: true,
	},
	{
		SelectedPlatform: Platform.MT5,
		Title: translate('common_open_account_mt5_title', 'MetaTrader 5'),
		Img: Mt5Img,
		Enable: userGuid && (env.WEBCAB_8772_enableMt5Open || [userGuid]).some(x => x.toLowerCase() === userGuid.toLowerCase())
	},
];

class CYOpenAccountChoosePlatform extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.OpenAccountOut) {
			errors.OpenAccountOut = {};

			if (!values.OpenAccountOut.SelectedPlatform) {
				errors.OpenAccountOut.SelectedPlatform = translate('shared_required', 'Required');
			}
		}

		return errors;
	}

	selectMethod = (platform) => {
		const { change } = this.props;
		const selectedOpenAccountOut = getOpenAccountOutConfiguration(platform);
		change('OpenAccountOut', selectedOpenAccountOut);
	};

	componentDidUpdate(prevProps) {
		if (!this.props.OpenAccountOut) return;

		// мы не можем сразу запустить handleSubmit() в selectMethod
		// т.к. change из react-redux выполняет обновление значения формы асинхронно
		this.props.handleSubmit();
	}

	render() {
		const {
			classes,
			handleSubmit,
			OpenAccountOut,
			userGuid,
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<Grid container spacing={3}>
					{getPlatformsViews(userGuid).map(p => (
						<Grid item xs={6} key={p.SelectedPlatform}>
							<div
								className={classNames({
									[classes.platformCard]: true,
									[classes.platformCardSelected]: OpenAccountOut?.SelectedPlatform === p.SelectedPlatform,
								})}
								onClick={() => p.Enable ? this.selectMethod(p.SelectedPlatform) : false}
							>
								<img src={p.Img} className={p.Enable ? classes.methodImg : classes.methodImgBW} />
								<div className={classes.methodTitle}>
									<h2>
										{p.Enable ? p.Title : <R id="open_account_platform_coming_soon" translateParameters={{ title: p.Title }} defaultValue="{title} coming soon" />}
									</h2>
								</div>
							</div>
						</Grid>

					))}
				</Grid>
				{/* <div className={classes.buttonCenter}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting || !OpenAccountOut.SelectedPlatform}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="common_btn_continue" defaultValue="Continue" />
						}
					</Button>
					</div> */}
			</form>
		);
	}
}

CYOpenAccountChoosePlatform.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		validate: CYOpenAccountChoosePlatform.validate,
	}),
)(CYOpenAccountChoosePlatform);
