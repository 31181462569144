import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ProgressContainer } from '@components';
import { R, Modal, SuccessMessage, ErrorMessage } from '@dev2t/react-ui-kit/src';
import { reset } from '@redux';
import { translate } from '@global';
import ResetByEmailForm from './components/ResetByEmailForm';
import ResetByOperatorForm from './components/ResetByOperatorForm';

export const ResetViewCommon = ({}) => {
	const [step, setStep] = useState('resetByEmail');
	const [error, setError] = useState('');
	const requestEmail = useSelector(reset.selectors.requestEmail);
	const dispatch = useDispatch();

	const submitting = false;

	const goToLogin = () => dispatch(push('/login'));
	const resetForm = () => setStep('resetByEmail');
	const titleModal = ` ${['resetByOperator', 'succeededOperator', 'failedOperator'].includes(step) ? '#2' : '#1'}`;

	return (
		<Modal
			title={translate('reset_form_title', 'Password recovery, step') + titleModal}
			onClose={goToLogin}
		>
			<ProgressContainer submitting={submitting}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={goToLogin}
							text={
								<React.Fragment>
									<p>
										<R id="reset_view_success_first" defaultValue="We have sent a email with a password recovery link." />
									</p>
									<p>
										<R id="reset_view_success_second" translateParameters={{ requestEmail }} defaultValue="Please check your email {requestEmail}" />
									</p>
								</React.Fragment>
							}
						/>
					}
					{(step === 'failed' || step === 'failedOperator') &&
						<ErrorMessage onClick={resetForm} text={error || translate('reset_view_error_password_recovery', 'Could not password recovery')} />
					}
					{step === 'resetByEmail' &&
						<ResetByEmailForm
							enableReinitialize
							forceUnregisterOnUnmount
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={(errorInfo) => { setError(errorInfo._error); setStep('failed'); }}
							onOperatorSended={() => setStep('resetByOperator')}
						/>
					}
					{step === 'succeededOperator' &&
						<SuccessMessage
							onClick={goToLogin}
							text={
								<React.Fragment>
									<p>
										<R id="reset_view_success_operator_first" defaultValue="Your form has been successfully sent." />

									</p>
									<p>
										<R id="reset_view_success_operator_second" defaultValue="Operator will contact you soon to help to recover password from your account." />
									</p>
								</React.Fragment>
							}
						/>
					}
					{step === 'resetByOperator' &&
						<ResetByOperatorForm
							enableReinitialize
							forceUnregisterOnUnmount
							onSuccess={() => setStep('succeededOperator')}
							onSubmitFail={(errorInfo) => { setError(errorInfo._error); setStep('failedOperator'); }}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};
