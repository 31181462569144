export function getPlatformFullName(platform, productType) {
	switch (platform) {
		case 'LIME:MT:USD':
		switch (productType)
		{
			case 'ForexEcn': return 'MT4 ECN'
			case 'ForexMUSLIM': return 'MT4 Swap Free'
			default: return 'MT4 Standard'
		}

		case 'LIME:MT:EUR': return 'FOREX MT4 (EUR)'
		case 'LIME:MT:EUR': return 'FOREX MT4 (EUR)'
		case 'LIME:MT:RUB': return 'FOREX MT4 (RUB)'
		case 'LIME:MMA2:USD': return 'MT5 Global (USD)'
		case 'LIME:MMA2:EUR': return 'MT5 Global (EUR)'
		case 'LIME:MMA2:RUB': return 'MT5 Global (RUB)'
		case 'LME:MMA2:USD': return 'MT5 Standard'
		case 'LME:MMA2:EUR': return 'MT5 Global (EUR)'
		case 'LME:MMA2:RUB': return 'MT5 Global (RUB)'
		case 'J2TX:MT5:USD': return 'MT5 Global (USD)'
		case 'J2TX:MT5:EUR': return 'MT5 Global (EUR)'
		case 'J2TX:WALLET': return 'J2TX Wallet'
		case 'J2TX:STAK': return 'J2TX Staking'
		case 'LIME:AGENT': return 'IB rebate account'
		default: return platform
	}
}

export function getAccountProductName(account) {
	switch (account.ProductType) {
		case 'ForexStandart':return 'Forex Standard';
		case 'ForexMUSLIM': return 'Forex Swap Free';
		case 'ForexEcn': return 'Forex ECN';
		case 'MMA2': return 'MT5 Global';
		default:
			return getPlatformFullName(account.Platform);
	}
}
