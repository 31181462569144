import React from 'react';
import { env, constants } from '@config';
import { ResetViewLme } from './ResetViewLme';
import { ResetViewCommon } from './ResetViewCommon';

export const ResetView = () => {
    switch (env.firm) {
        case constants.LIME_LME:
            return <ResetViewLme/>
        default:
            return <ResetViewCommon/>
    }
}