
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { env, constants } from '@config';

export function useAllowedWalletCryptoCurrencies() {
    const userInfo = useSelector(userAccounts.selectors.userInfo);

    if(env.WEBCAB_8810_ALLOWD_USDT_J2TX.some(x => x.toLowerCase() === userInfo.Guid)) {
        return [...constants.J2txWalletAllowedCryptoCurrencies, 'USDT'];
    }

    return constants.J2txWalletAllowedCryptoCurrencies;
}
