import React, { useEffect, useState } from 'react';
import { userAccounts, withdrawFunds } from '@redux';
import { useSelector } from 'react-redux';
import {
	
	ProgressContainer,
} from '@components';
import { R, Modal, ErrorMessage, SuccessMessage } from '@dev2t/react-ui-kit/src';
import { ModalPopups } from '@enums';
import { useBalance, useAccount } from '@hooks';

import J2txTransferForm from './J2txTransferForm';
import getDestinationAccounts from './getDestinationAccounts';
import getAvailableCurrencies from './getAvailableCurrencies';


const J2txTransferModal = ({ onClose, isMt5Global }) => {
	const form = ModalPopups.TRANSFER_WALLET_TO_MT5;
	const [step, setStep] = useState('');

	const { tradeCodeFrom, tradeCodeTo, currencyCode } = useSelector(userAccounts.selectors.modals)[form];

	if (!tradeCodeFrom) throw new Error('tradeCodeFrom not provided');
	const sourceAccount = useAccount(tradeCodeFrom);

	// вычисляем счет куда переводим
	const destinationAccounts = getDestinationAccounts(sourceAccount);

	const destinationAccount = tradeCodeTo
		? useAccount(tradeCodeTo)
		: destinationAccounts[0];

	// вычисляем вылюту
	const currency = currencyCode ?? getAvailableCurrencies(sourceAccount, destinationAccount, isMt5Global)[0];
	
	const initialValues = {
		TradeCodeFrom: tradeCodeFrom,
		ClearingFirmFrom: sourceAccount.ClearingFirm,
		SummCurrency: currency,
		TradeCodeTo: destinationAccount.TradeCode,
		ClearingFirmTo: destinationAccount.ClearingFirm,
	}
	
	const accountsInfoLoading = useSelector(userAccounts.selectors.accountsInfoLoading);	
	const balance = useBalance(tradeCodeFrom, isMt5Global);

	useEffect(() => {
		if (!sourceAccount?.CanTransferFrom) {
			setStep('cantTransferFrom');
		} else if (!accountsInfoLoading && !['failed', 'succeeded'].includes(step)) {
			if (balance.length === 0) {
				setStep('noMoney');
			} else if (destinationAccounts.every(x => x.IsWallet) && destinationAccounts.length === 0) {
				setStep('noWalletAccounts');
			} else if (destinationAccounts.every(x => x.IsMT5) && destinationAccounts.length === 0) {
				setStep('noMt5Accounts');
			} else if (destinationAccounts.every(x => x.IsStaking) && destinationAccounts.length === 0) {
				setStep('noStakingAccounts');
			} else {
				setStep('amountform');
			}
		}
	}, [tradeCodeFrom, accountsInfoLoading]);

	const onSuccess = () => setStep('succeeded');
	const onFail = () => setStep('failed');

	return (
		<Modal
			title={<R id="title_transfer_to_another_account" defaultValue="Transfer to another account" />}
			onClose={onClose}
			className={`QA-MODAL-${form}`}
		>
			<ProgressContainer
				submitting={accountsInfoLoading}
			>
				<div>
					{step === 'noMoney' &&
						<ErrorMessage
							onClick={onClose}
							text={<R
								id="j2txwallet_notenough_money"
								defaultValue="Insufficient funds for withdrawal on account {accountName}"
								translateParameters={{ accountName: tradeCodeFrom }}
							/>}
						/>
					}
					{step === 'cantTransferFrom' &&
						<ErrorMessage
							onClick={onClose}
							text={<R
								id="j2txwallet_cant_transfer_money_from"
								defaultValue="It is not possible to transfer money from the selected account"
							/>}
						/>
					}
					{step === 'noWalletAccounts' &&
						<ErrorMessage
							onClick={onClose}
							text={<R
								id="j2txwallet_no_wallet_accounts"
								defaultValue="You have no Wallet accounts"
							/>}
						/>
					}
					{step === 'noMt5Accounts' &&
						<ErrorMessage
							onClick={onClose}
							text={(
								<R
									id="j2txwallet_no_mt5global_accounts"
									defaultValue="You have no MT5 Global accounts"
								/>
							)}
						/>
					}
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={[destinationAccount, sourceAccount].some(a => a.IsStaking) ? (
								<R 
									id="common_banking_transaction_processing" 
									defaultValue="Banking transaction has been accepted for processing" 
								/>
							) : (
								<R 
									id="common_banking_transaction_completed" 
									defaultValue="Banking transaction completed" 
								/>
							)}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R
								id="common_could_not_complete_transaction"
								defaultValue="Could not complete transaction"
							/>}
						/>
					}
					{step === 'amountform' &&
						<J2txTransferForm
							onSubmit={withdrawFunds.actions.transferBetweenAccountsSubmitForm}
							initialValues={initialValues}

							/* keepDirtyOnReinitialize */
							forceUnregisterOnUnmount
							enableReinitialize
							isMt5Global={isMt5Global}
							onSuccess={onSuccess}
							onFail={onFail}

							form={form}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

J2txTransferModal.propTypes = {
	// // название формы
	// form: PropTypes.string.isRequired,

	// // title на форме
	// title: PropTypes.oneOfType([
	// 	PropTypes.string,
	// 	PropTypes.element,
	//   ]),
	// onClose: PropTypes.func.isRequired,
	// onSubmit: PropTypes.func.isRequired,

	// // нижние ограничения на переводы (если есть) 
	// restrictions: PropTypes.shape({
	// 	BTC: PropTypes.number,
	// 	ETH: PropTypes.number,
	// 	USDT: PropTypes.number,
	// 	LTC: PropTypes.number,
	// 	XRP: PropTypes.number,
	//   }),

	// // валюта из которой переводим (если есть) 
	// currencyFrom: PropTypes.string,
	// tradeCodeFrom: PropTypes.string,

	// // функция для вычисления списка валют, которые можно переводить
	// calculateCurrencies: PropTypes.func,

	// // счета, которые будут в выпадашке куда
	// destinationAccounts: PropTypes.arrayOf(PropTypes.shape({
	// 	TradeCode: PropTypes.string.isRequired,
	// 	ClearingFirm: PropTypes.string.isRequired,
	// 	DisplayFullName: PropTypes.string.isRequired,
	// 	IsDemo: PropTypes.bool.isRequired,
	// })).isRequired,

	// 	// счета, которые будут в выпадашке откуда
	// sourceAccounts: PropTypes.arrayOf(PropTypes.shape({
	// 	TradeCode: PropTypes.string.isRequired,
	// 	ClearingFirm: PropTypes.string.isRequired,
	// 	DisplayFullName: PropTypes.string.isRequired,
	// 	IsDemo: PropTypes.bool.isRequired,
	// })).isRequired,
};

export default J2txTransferModal;
